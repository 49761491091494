import Head from 'next/head';
import { useContext, useEffect, useState } from 'react';
import { getPageSEO } from '../../pages/api/hello';
import { useRouter } from 'next/router';
import AppContext from '../../store/AppContext';
import environment from '../../utils/environment';
import moment from 'moment';

const HeadComponent = ({ isMain = false, seoRes = null }: { isMain?: boolean, seoRes?: any }) => {
    const { generalSetting } = useContext(AppContext);
    const { type, seo } = useContext(AppContext);
    const [url, setUrl] = useState("");
    const router = useRouter();

    //@ts-ignore
    useEffect(() => {

        setUrl(window?.location?.href);

    }, [])

    useEffect(() => {

        //loadSeo(window?.location?.pathname.slice(0, window?.location?.pathname.length - 1) ?? "/");
        setUrl(window?.location?.href);
    }, [router])

    useEffect(() => {
        //loadSeo(window?.location?.pathname.slice(0, window?.location?.pathname.length - 1) ?? "/");
    }, [url])


    return <><Head>
        <title>{seo?.title != "" ? seo?.title : "Hoài Giang shop - Chuyên may, bán và cho thuê trang phục"}</title>
        <meta key={"description"} name="description" content={seo?.description ?? "Hoài Giang shop chuyên may bán, thiết kế các loại trang phục áo dài, công sở, biểu diễn, hóa trang, dân tộc... chất lượng cho quý khách với giá cả hợp lý."} />
        <meta key={'keywords'} name={'keywords'} content={seo?.keyword?.join(",")} />
        <meta key={"og:title"} property={'og:title'} content={seo?.title && seo?.title != "" ? seo?.title : "Hoài Giang shop - Chuyên may, bán và cho thuê trang phục"} />
        <meta key={"og:description"} property={'og:description'} content={seo?.description ?? "Hoài Giang shop chuyên may bán, thiết kế các loại trang phục áo dài, công sở, biểu diễn, hóa trang, dân tộc... chất lượng cho quý khách với giá cả hợp lý."} />
        <meta key={"og:locale"} property={'og:locale'} content={"vi_VN"} />
        <meta key={"og:type"} property={'og:type'} content={'website'} />
        <meta key={"og:url"} property={'og:url'} content={url} />
        <meta key={"og:image"} property={'og:image'} content={url} />
        <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <meta key={"og:site_name"} property={'og:site_name'} content={"Hoài Giang shop"} />
        <meta key={"og:updated_time"} property={'og:updated_time'} content={moment(seo?.updated_date ?? new Date()).format()} />
        <meta key={"article:published_time"} property={'article:published_time'} content={moment(seo?.created_date ?? new Date()).format()} />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:title" content={seo?.title && seo?.title != "" ? seo?.title : "Hoài Giang shop - Chuyên may, bán và cho thuê trang phục"} />
        <meta name="twitter:description" content={seo?.description ?? "Hoài Giang shop chuyên may bán, thiết kế các loại trang phục áo dài, công sở, biểu diễn, hóa trang, dân tộc... chất lượng cho quý khách với giá cả hợp lý."} />
        <meta name="msapplication-TileImage" content="https://hoaigiangshop.com/wp-content/uploads/2019/09/cropped-hgs-fav-510x510.png"></meta>
        {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
        <link rel="icon" href={generalSetting?.system_config?.system_image_favicon ? environment.baseUsmImageUrl + generalSetting?.system_config?.system_image_favicon : "/asset/icon/logo.svg"} />
        <link rel="canonical" href={url} />
        {/* <link rel="stylesheet" href="https://cdn.syncfusion.com/ej2/tailwind.css" crossOrigin="anonymous" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </Head>
    </>
}

export default HeadComponent;
