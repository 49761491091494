import { constant } from "./constant";

export function setStorage(id: number, name: string) {
    let data = {
        id: id,
        name: name
    }
    localStorage.setItem(constant.product, JSON.stringify(data));
}

export function addKeyToImage(url: string, type: number, isBodyHome = false, is500 = false, is350 = false, is960 = false) {

    let newUrl = url.slice(0, url.indexOf("."));
    let format = ".webp";
    if (is960) {
        return newUrl + "_960" + format;
    }
    if (is500) {
        return newUrl + "_530" + format;
    }
    if (is350) {
        return newUrl + "_350" + format;
    }
    if (isBodyHome && type !== 3) {
        return newUrl + "_630" + format;
    }
    switch (type) {
        case 1:
            return newUrl + "_530" + format;
            break;
        case 2:
            return newUrl + "_530" + format;
            break;
        case 3:
            return newUrl + "_530" + format;
            break;
        default:
            break;
    }
    return ""
}

export function getLastUrlSegment(url:string) {
    return new URL(url).pathname.split('/').filter(Boolean).pop();
}